import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Outlet } from "react-router-dom";
import NavBar from "../components/Shared/NavBar/NavBar";
import Footer from "../components/Shared/Footer/Footer";
import ScrollTop from "../components/ScrollTop";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
import store from "../store";

const MainLayout = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "pageview",
        pagePath: location.pathname,
      });
    }
  }, [location.pathname]);

  return (
    <Provider store={store}>
      <div className="flex flex-col h-screen">
        <ScrollTop />
        <NavBar />
        <Toaster position="bottom-right" reverseOrder={false} gutter={1} />
        <main className="flex-grow bg-white">
          <Outlet />
        </main>
        <Footer />
      </div>
    </Provider>
  );
};

export default MainLayout;
