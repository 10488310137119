import React, { useState, useEffect, useRef } from "react";
import {
  ChevronUp,
  ShoppingBag,
  Shield,
  Truck,
  CreditCard,
  Users,
  FileText,
  Lock,
} from "lucide-react";

export default function AboutKenoToday() {
  const [activeSection, setActiveSection] = useState("");
  const observer = useRef(null);
  const sectionsRef = useRef({});

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      { rootMargin: "0px 0px -70% 0px", threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1] }
    );

    Object.values(sectionsRef.current).forEach((section) => {
      if (section) observer.current.observe(section);
    });

    return () => observer.current.disconnect();
  }, []);

  useEffect(() => {
    document.documentElement.style.scrollBehavior = "smooth";
    return () => {
      document.documentElement.style.scrollBehavior = "";
    };
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const sectionParam = urlParams.get("section");

    if (sectionParam && sectionsRef.current[sectionParam]) {
      sectionsRef.current[sectionParam].scrollIntoView({ behavior: "smooth" });
      setActiveSection(sectionParam);
    }
  }, []);


  const sections = [
    { id: "about", title: "About Keno Today", icon: ShoppingBag },
    { id: "buyer-protection", title: "Buyer Protection", icon: Shield },
    { id: "delivery-options", title: "Delivery Options", icon: Truck },
    { id: "making-payments", title: "Making Payments", icon: CreditCard },
    { id: "join-community", title: "Join Our Community", icon: Users },
    { id: "terms-conditions", title: "Terms & Conditions", icon: FileText },
    { id: "privacy", title: "Privacy", icon: Lock },
  ];

  return (
    <div className="min-h-screen bg-gray-100 text-gray-900">
      <header className="py-8 flex items-center justify-center">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-bold">About Keno Today</h1>
          <p className="mt-2 text-xl">Your Premier E-commerce Destination</p>
        </div>
      </header>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="lg:grid lg:grid-cols-4 lg:gap-8">
          <nav className="hidden lg:block sticky top-28 self-start">
            <ul className="space-y-2 bg-white rounded-lg shadow p-4">
              {sections.map((section) => (
                <li key={section.id}>
                  <a
                    href={`#${section.id}`}
                    className={`flex items-center py-2 px-3 rounded transition-colors 
                     ${activeSection === section.id
                        ? "bg-ourPrimary text-white"
                        : "hover:bg-slate-200"
                      }
                    `}
                  >
                    <section.icon className="w-5 h-5 mr-2" />
                    {section.title}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
          <div className="mt-8 lg:mt-0 lg:col-span-3">
            <div className="prose max-w-none">
              <section
                id="about"
                ref={(el) => (sectionsRef.current["about"] = el)}
                className="scroll-mt-16"
              >
                <h2 className="text-3xl font-semibold mb-4 flex items-center">
                  <ShoppingBag className="w-8 h-8 mr-2 text-ourPrimary" />
                  About Keno Today
                </h2>
                <p>
                  Keno Today is a leading e-commerce platform in the USA,
                  offering a wide range of products and an exceptional shopping
                  experience. Founded in 2010, we've grown from a small startup
                  to a trusted marketplace serving millions of customers
                  nationwide.
                </p>
                <p>
                  Our mission is to connect customers with quality products
                  while ensuring convenience, security, and satisfaction. We
                  partner with thousands of sellers, from small businesses to
                  major brands, to bring you an unparalleled selection of items
                  across various categories.
                </p>
                <p>
                  At Keno Today, we're committed to innovation, constantly
                  improving our platform to make your shopping experience
                  smoother, faster, and more enjoyable. Our dedicated team works
                  tirelessly to curate the best products, negotiate great deals,
                  and ensure that every transaction on our platform is safe and
                  secure.
                </p>
              </section>

              <section
                id="buyer-protection"
                ref={(el) => (sectionsRef.current["buyer-protection"] = el)}
                className="mt-10 scroll-mt-16"
              >
                <h2 className="text-3xl font-semibold mb-4 flex items-center">
                  <Shield className="w-8 h-8 mr-2 text-ourPrimary" />
                  Buyer Protection
                </h2>
                <p>
                  At Keno Today, we prioritize your peace of mind. Our
                  comprehensive Buyer Protection program safeguards your
                  purchases, ensuring you receive exactly what you ordered or
                  your money back. We handle disputes fairly and efficiently,
                  always putting our customers first.
                </p>
                <h3 className="text-xl font-semibold mt-4 mb-2">
                  Key Features of Our Buyer Protection:
                </h3>
                <ul className="list-disc pl-6">
                  <li>
                    Full refunds for items not received or significantly
                    different from description
                  </li>
                  <li>Protection against unauthorized purchases</li>
                  <li>Extended warranty on eligible items</li>
                  <li>24/7 customer support to address your concerns</li>
                </ul>
                <p className="mt-4">
                  We also verify all sellers on our platform to ensure they meet
                  our high standards of product quality and customer service.
                  With Keno Today, you can shop with confidence, knowing that
                  we've got your back every step of the way.
                </p>
              </section>

              <section
                id="delivery-options"
                ref={(el) => (sectionsRef.current["delivery-options"] = el)}
                className="mt-10 scroll-mt-16"
              >
                <h2 className="text-3xl font-semibold mb-4 flex items-center">
                  <Truck className="w-8 h-8 mr-2 text-ourPrimary" />
                  Delivery Options
                </h2>
                <p>
                  We offer flexible delivery options to suit your needs. From
                  standard shipping to express delivery, we ensure your
                  purchases reach you in a timely manner. Our partnerships with
                  reliable courier services guarantee the safe handling of your
                  items.
                </p>
                <h3 className="text-xl font-semibold mt-4 mb-2">
                  Our Delivery Services:
                </h3>
                <ul className="list-disc pl-6">
                  <li>Standard Shipping (3-5 business days)</li>
                  <li>Express Delivery (1-2 business days)</li>
                  <li>Same-Day Delivery (available in select cities)</li>
                  <li>International Shipping (varies by destination)</li>
                </ul>
                <p className="mt-4">
                  We also offer order tracking for all shipments, allowing you
                  to monitor your package's journey from our warehouse to your
                  doorstep. For eco-conscious shoppers, we provide green
                  shipping options that use sustainable packaging and
                  carbon-neutral delivery methods.
                </p>
              </section>

              <section
                id="making-payments"
                ref={(el) => (sectionsRef.current["making-payments"] = el)}
                className="mt-10 scroll-mt-16"
              >
                <h2 className="text-3xl font-semibold mb-4 flex items-center">
                  <CreditCard className="w-8 h-8 mr-2 text-ourPrimary" />
                  Making Payments
                </h2>
                <p>
                  Keno Today supports a variety of secure payment methods for
                  your convenience. We accept major credit cards including
                  Mastercard and Visa, as well as digital payment options like
                  Google Pay and Apple Pay. All transactions are encrypted to
                  ensure your financial information remains safe.
                </p>
                <h3 className="text-xl font-semibold mt-4 mb-2">
                  Supported Payment Methods:
                </h3>
                <ul className="list-disc pl-6">
                  <li>
                    Credit/Debit Cards (Visa, Mastercard, American Express,
                    Discover)
                  </li>
                  <li>Digital Wallets (Apple Pay, Google Pay, PayPal)</li>
                  <li>Bank Transfers</li>
                  <li>Keno Today Gift Cards</li>
                </ul>
                <p className="mt-4">
                  We also offer a secure, interest-free installment payment
                  option for eligible purchases, allowing you to split your
                  payment into manageable monthly installments. Rest assured,
                  all payment data is handled with the highest level of
                  security, using industry-standard encryption and fraud
                  detection systems.
                </p>
              </section>

              <section
                id="join-community"
                ref={(el) => (sectionsRef.current["join-community"] = el)}
                className="mt-10 scroll-mt-16"
              >
                <h2 className="text-3xl font-semibold mb-4 flex items-center">
                  <Users className="w-8 h-8 mr-2 text-ourPrimary" />
                  Join Our Community
                </h2>
                <p>
                  Become part of the Keno Today community! Follow us on social
                  media, subscribe to our newsletter, and participate in our
                  forums to stay updated on the latest deals, product launches,
                  and community events. Your voice matters in shaping the future
                  of Keno Today.
                </p>
                <h3 className="text-xl font-semibold mt-4 mb-2">
                  Community Benefits:
                </h3>
                <ul className="list-disc pl-6">
                  <li>Early access to sales and promotions</li>
                  <li>Exclusive member-only discounts</li>
                  <li>Product review program with rewards</li>
                  <li>Community forums for sharing tips and experiences</li>
                  <li>
                    Opportunity to participate in product development surveys
                  </li>
                </ul>
                <p className="mt-4">
                  Join our loyalty program to earn points on every purchase,
                  which can be redeemed for discounts or special items. Engage
                  with fellow shoppers, share your experiences, and help us
                  create the best possible shopping platform for everyone.
                </p>
              </section>

              <section
                id="terms-conditions"
                ref={(el) => (sectionsRef.current["terms-conditions"] = el)}
                className="mt-10 scroll-mt-16"
              >
                <h2 className="text-3xl font-semibold mb-4 flex items-center">
                  <FileText className="w-8 h-8 mr-2 text-ourPrimary" />
                  Terms & Conditions
                </h2>
                <p>
                  Our Terms & Conditions outline the rules and regulations for
                  using the Keno Today platform. They cover important aspects
                  such as user responsibilities, intellectual property rights,
                  and dispute resolution procedures. We encourage all users to
                  familiarize themselves with these terms to ensure a smooth
                  shopping experience.
                </p>
                <h3 className="text-xl font-semibold mt-4 mb-2">Key Points:</h3>
                <ul className="list-disc pl-6">
                  <li>User account responsibilities and security</li>
                  <li>Product listing and selling guidelines</li>
                  <li>Prohibited items and activities</li>
                  <li>Intellectual property protection</li>
                  <li>Dispute resolution and arbitration procedures</li>
                </ul>
                <p className="mt-4">
                  Our Terms & Conditions are designed to create a fair and safe
                  environment for all users. They are regularly updated to
                  reflect changes in laws and our services. We recommend
                  reviewing them periodically to stay informed about your rights
                  and responsibilities on our platform.
                </p>
              </section>

              <section
                id="privacy"
                ref={(el) => (sectionsRef.current["privacy"] = el)}
                className="mt-10 scroll-mt-16"
              >
                <h2 className="text-3xl font-semibold mb-4 flex items-center">
                  <Lock className="w-8 h-8 mr-2 text-ourPrimary" />
                  Privacy
                </h2>
                <p>
                  At Keno Today ("we," "us," or "our"), we are committed to protecting your privacy and ensuring that your personal data is handled in a safe and responsible manner. This Privacy Policy explains how we collect, use, store, and protect your personal information when you use our grocery eCommerce app.
                  <br />
                  By using the Keno Today app and services, you agree to the terms outlined in this privacy policy.
                </p>
                <h3 className="text-xl font-semibold mt-4 mb-2">
                  Our Commitment to Your Privacy:
                </h3>
                <ul className="list-disc pl-6">
                  <li>Transparent data collection and usage practices</li>
                  <li>State-of-the-art encryption for all personal and financial data</li>
                  <li>Strict access controls and regular security audits</li>
                  <li>Options to manage your privacy settings and data</li>
                  <li>Compliance with global data protection regulations</li>
                </ul>
                <p className="mt-4">
                  We believe in giving you control over your data. You can easily access, modify, or delete your personal information through your account settings. Our dedicated privacy team is always available to address any concerns or questions you may have about your data on Keno Today.
                </p>


                <h3 className="text-xl font-semibold mt-4 mb-2">
                  Data Collection and Use:
                </h3>
                <p>
                  We collect the following types of information:
                </p>
                <ul className="list-disc pl-6">
                  <li>
                    Personal information such as your name, email address, phone number, and delivery address to process your orders and provide personalized services.
                  </li>
                  <li>
                    Payment details (e.g., credit card information) for payment processing. These details are securely encrypted and not stored on our servers.
                  </li>
                  <li>
                    Usage data including app interactions, preferences, and technical information such as IP addresses, browser type, and device details.
                  </li>
                </ul>


                <h3 className="text-xl font-semibold mt-4 mb-2">
                  Location Data
                </h3>
                <p>
                  Keno Today collects and uses your location data to enhance your shopping experience and offer location-specific services, such as delivering groceries to your area or providing store pickup options. By using the Keno Today app, you consent to the collection and use of location data as described in this section.
                </p>

                <h4 className="text-lg font-semibold mt-4 mb-2">
                  How We Use Your Location Data:
                </h4>
                <ul className="list-disc pl-6">
                  <li>To provide location-based delivery options and store pickup services.</li>
                  <li>To suggest nearby stores and delivery zones for your convenience.</li>
                  <li>To optimize the app's functionality based on your location, such as offering relevant promotions or services.</li>
                </ul>

                <h4 className="text-lg font-semibold mt-4 mb-2">
                  Location Data Collection:
                </h4>
                <p>
                  We collect your location data in the following ways:
                </p>
                <ul className="list-disc pl-6">
                  <li>Through GPS or Wi-Fi signals when you use location-based features in the app.</li>
                  <li>Via IP address for general location information (such as city or region).</li>
                </ul>

                <h4 className="text-lg font-semibold mt-4 mb-2">
                  Control Over Your Location Data:
                </h4>
                <p>
                  You have control over whether the app can access your location data:
                </p>
                <ul className="list-disc pl-6">
                  <li>You can enable or disable location access through your device's settings at any time.</li>
                  <li>If you disable location access, some features of the app, such as location-based delivery options, may not function as expected.</li>
                </ul>

                <h4 className="text-lg font-semibold mt-4 mb-2">
                  Data Retention and Sharing:
                </h4>
                <p>
                  We do not store your precise location data after it is used to provide the requested service. However, we may retain general location data for the purposes of improving our services or complying with legal obligations. We will not share your location data with third parties for advertising purposes.
                </p>

                <h4 className="text-lg font-semibold mt-4 mb-2">
                  Third-Party Services:
                </h4>
                <p>
                  We may use third-party services, such as Google Maps or similar providers, to enhance location-based functionality. These third-party services may collect and process your location data in accordance with their own privacy policies. Please refer to their respective privacy policies for more information.
                </p>

                <h4 className="text-lg font-semibold mt-4 mb-2">
                  Your Privacy Choices:
                </h4>
                <p>
                  You can manage your location data preferences at any time by adjusting the permissions in your device settings. If you have any concerns about how your location data is used, please feel free to contact us.
                </p>

                <h3 className="text-xl font-semibold mt-4 mb-2">
                  Data Safety
                </h3>
                <p>
                  We take your data security seriously and employ industry-standard security measures to protect your personal information. We continuously work to maintain a high level of security to safeguard your data from unauthorized access, alteration, or disclosure.
                </p>

                <h4 className="text-lg font-semibold mt-4 mb-2">
                  How We Secure Your Data:
                </h4>
                <ul className="list-disc pl-6">
                  <li>All data is transmitted over secure HTTPS connections to prevent interception.</li>
                  <li>Personal information, including payment details, is encrypted both in transit and at rest.</li>
                  <li>We employ firewalls, intrusion detection systems, and secure access controls to protect our systems.</li>
                  <li>We conduct regular security audits and vulnerability assessments to identify and address potential risks.</li>
                  <li>Only authorized personnel have access to sensitive information, and they are trained in handling data securely.</li>
                </ul>

                <h4 className="text-lg font-semibold mt-4 mb-2">
                  Your Role in Data Safety:
                </h4>
                <p>
                  While we implement robust security measures, your cooperation is also essential in maintaining data safety:
                </p>
                <ul className="list-disc pl-6">
                  <li>Use strong passwords and update them regularly to protect your account.</li>
                  <li>Log out of your account when using public or shared devices.</li>
                  <li>Be cautious when sharing personal information online, and avoid sharing sensitive data through unsecured channels.</li>
                </ul>

                <h4 className="text-lg font-semibold mt-4 mb-2">
                  Reporting Security Concerns:
                </h4>
                <p>
                  If you believe there has been a breach of your data or have any concerns about the security of your information, please contact us immediately. We are committed to investigating and addressing any security issues promptly.
                </p>

                <h3 className="text-xl font-semibold mt-4 mb-2">
                  How We Protect Your Data:
                </h3>
                <p>
                  We use industry-leading security measures to protect your data, including:
                </p>
                <ul className="list-disc pl-6">
                  <li>Encrypted communication over HTTPS for all transactions</li>
                  <li>Secure data storage and access restrictions</li>
                  <li>Regular security audits to ensure data protection standards are met</li>
                </ul>

                <h3 className="text-xl font-semibold mt-4 mb-2">
                  Your Rights:
                </h3>
                <p>
                  You have the right to:
                </p>
                <ul className="list-disc pl-6">
                  <li>Access your personal information at any time through your account</li>
                  <li>Modify or update your information if it's incorrect or outdated</li>
                  <li>Request the deletion of your personal data in accordance with applicable laws</li>
                </ul>

                <h3 className="text-xl font-semibold mt-4 mb-2">
                  Contact Us:
                </h3>
                <p>
                  If you have any questions, concerns, or requests regarding your privacy, feel free to reach out to us through the contact information available on the app. Our team is committed to ensuring that your data is handled responsibly and securely.
                </p>

                <h3 className="text-xl font-semibold mt-4 mb-2">
                  Changes to This Privacy Policy:
                </h3>
                <p>
                  We may update our privacy policy from time to time. Any changes will be posted in this section, and the updated policy will be effective immediately upon posting. We encourage you to review this privacy policy periodically to stay informed about how we are protecting your data.
                </p>
              </section>



            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
