import React, { useEffect, useMemo, useState } from "react";
import AccountNav from "../../components/Shared/AccountNav/AccountNav";
import NavigationMenu from "./NavigationMenu";
import { HomeIcon, Phone } from "lucide-react";
import { FaAngleRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/Select";
import CryptoJS from "crypto-js";
import { useDispatch, useSelector } from "react-redux";
import {
  changePassword,
  get_userinfo,
  messageClear,
  update_profile,
  upload_profile_photo,
} from "../../store/reducers/HomeReducer";
import toast from "react-hot-toast";
import { countries, usStates } from "../../utils/commons";
import { MdModeEdit } from "react-icons/md";
import { CustomSearchableSelect } from "../Checkout/CitySelection";

const API_URL = process.env.REACT_APP_API_URL;

const Profile = () => {
  const [image, setImage] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [number, setNumber] = useState("");
  const [address, setAddress] = useState("");
  const [address2, setAddress2] = useState("");
  const [email, setEmail] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [city, setCity] = useState("");
  const [selectedState, setSelectedState] = useState("New York");
  const [country, setCountry] = useState("United States");
  const [zipCode, setZipCode] = useState("");
  const [userInfo, setUserInfo] = useState();
  const [originalPassword, setOriginalPassword] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [isChangingPassword, setIsChangingPassword] = useState(false);

  const dispatch = useDispatch();
  const {
    loading: loader,
    successMessage,
    errorMessage,
    user_info,
  } = useSelector((state) => state.home);

  useEffect(() => {
    if (user_info) {
      setFirstName(user_info?.first_name || "");
      setLastName(user_info?.last_name || "");
      setEmail(user_info?.email || "");
      setNumber(user_info?.mobile_no || "");
      setAddress(user_info?.address?.address_line1 || "");
      setAddress2(user_info?.address?.address_line2 || "");
      setCity(user_info?.address?.city || "");
      setSelectedState(user_info?.address?.state || "New York");
      setCountry(user_info?.address?.country || "United States");
      setZipCode(user_info?.address?.pincode || "");
    }
  }, [user_info]);

  useEffect(() => {
    dispatch(get_userinfo());
  }, [dispatch]);

  const pass = localStorage.getItem("userSecret");
  const bytes = CryptoJS.AES.decrypt(pass, "secretkenotoday");
  const key = bytes.toString(CryptoJS.enc.Utf8);

  useEffect(() => {
    if (key) {
      setOriginalPassword(key);
    }
  }, [key]);

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
      dispatch(messageClear());
      setCurrentPassword("");
      setNewPassword("");
      dispatch(get_userinfo());
    } else if (errorMessage) {
      toast.error(errorMessage);
      dispatch(messageClear());
    }
  }, [successMessage, errorMessage, dispatch]);

  //functions
  // Regular expression for validating ZIP Code (basic 5-digit or ZIP+4 format)
  const zipRegex = /^\d{5}(-\d{4})?$/;

  // Helper function to validate a single ZIP Code
  const validateSingleZipCode = (zipCode) => {
    const isZipValid = zipRegex.test(zipCode);
    if (!isZipValid) {
      toast.error("Please enter a valid ZIP Code.");
      return false;
    }
    return true;
  };

  const handleSave = async () => {
    // Validate ZIP Code before proceeding
    // const isZipCodeValid = validateSingleZipCode(zipCode);
    // if (!isZipCodeValid) return;

    // Proceed if ZIP Code is valid and other fields are filled
    if (
      firstName &&
      lastName &&
      number &&
      email &&
      address &&
      city &&
      zipCode &&
      selectedState &&
      country
    ) {
      setIsSaving(true);
      try {
        await dispatch(
          update_profile({
            first_name: firstName,
            last_name: lastName,
            mobile_no: number,
            email,
            billing_address: {
              address_line1: address,
              address_line2: address2,
              city,
              state: selectedState,
              pincode: zipCode,
              country,
            },
          })
        );
      } finally {
        setIsSaving(false);
      }
    } else {
      toast.error("Fill all Inputs!");
    }
  };

  const handleChangePassword = async () => {
    if (currentPassword && newPassword) {
      setIsChangingPassword(true);
      try {
        await dispatch(
          changePassword({
            current_password: currentPassword,
            new_password: newPassword,
          })
        );
      } finally {
        setIsChangingPassword(false);
      }
    } else {
      toast.error("Please Enter Password!");
    }
  };

  const updateProfilePicture = async (e) => {
    try {
      const file = e.target.files[0];
      if (file) {
        const form = new FormData();
        form.append("image_file", file); // Attach file to form data
        dispatch(upload_profile_photo(form));
      }
    } catch (error) {
      console.error("Error uploading profile picture:", error);
    }
  };

  const neighborhoodData = {
    Queens: {
      Astoria: ["11102", "11103", "11105", "11106"],
      Auburndale: ["11358"],
      Bayside: ["11360", "11361", "11364"],
      "Belle Harbor": ["11694"],
      Bellerose: ["11426"],
      Briarwood: ["11435"],
      "Broad Channel": ["11693"],
      "Cambria Heights": ["11411"],
      "College Point": ["11356"],
      Corona: ["11368"],
      Douglaston: ["11362", "11363"],
      "East Elmhurst": ["11369", "11370"],
      Elmhurst: ["11373"],
      "Far Rockaway": ["11691"],
      Flushing: ["11354", "11355", "11367"],
      "Forest Hills": ["11375"],
      "Fresh Meadows": ["11365", "11366"],
      Glendale: ["11385"],
      Hollis: ["11423"],
      "Howard Beach": ["11414"],
      "Jackson Heights": ["11372"],
      Jamaica: ["11432", "11433", "11434", "11435", "11436"],
      "Jamaica Estates": ["11432"],
      "Kew Gardens": ["11415"],
      Laurelton: ["11413"],
      "Little Neck": ["11362"],
      "Long Island City": ["11101", "11109", "11120"],
      Maspeth: ["11378"],
      "Middle Village": ["11379"],
      "Ozone Park": ["11416", "11417"],
      "Queens Village": ["11427", "11428", "11429"],
      "Rego Park": ["11374"],
      "Richmond Hill": ["11418"],
      Ridgewood: ["11385"],
      "Rockaway Park": ["11694"],
      Rosedale: ["11422"],
      "South Ozone Park": ["11420"],
      "Springfield Gardens": ["11413"],
      Sunnyside: ["11104"],
      Whitestone: ["11357"],
      Woodhaven: ["11421"],
      Woodside: ["11377"],
    },
    "Nassau County": {
      Albertson: ["11507"],
      "Atlantic Beach": ["11509"],
      Baldwin: ["11510"],
      "Baldwin Harbor": ["11510"],
      "Bay Park": ["11518"],
      Bayville: ["11709"],
      "Bellerose Terrace": ["11001"],
      Bethpage: ["11714"],
      Brookville: ["11545"],
      "Carle Place": ["11514"],
      Cedarhurst: ["11516"],
      "Centre Island": ["11771"],
      "East Hills": ["11548", "11576"],
      "East Meadow": ["11554"],
      "East Norwich": ["11732"],
      Elmont: ["11003"],
      Farmingdale: ["11735"],
      "Floral Park": ["11001", "11002"],
      "Franklin Square": ["11010"],
      Freeport: ["11520"],
      "Garden City": ["11530", "11531"],
      "Glen Cove": ["11542"],
      "Glen Head": ["11545"],
      "Glenwood Landing": ["11547"],
      "Great Neck": ["11020", "11021", "11023", "11024", "11026"],
      "Great Neck Estates": ["11021"],
      "Great Neck Plaza": ["11021"],
      Greenvale: ["11548"],
      Hempstead: ["11550"],
      Herricks: ["11507"],
      Hewlett: ["11557"],
      Hicksville: ["11801"],
      Inwood: ["11096"],
      "Island Park": ["11558"],
      Jericho: ["11753"],
      Kensington: ["11021"],
      "Kings Point": ["11024"],
      "Lake Success": ["11020"],
      Levittown: ["11756"],
      "Lido Beach": ["11561"],
      Lynbrook: ["11563"],
      Malverne: ["11565"],
      Manhasset: ["11030"],
      Massapequa: ["11758"],
      "Massapequa Park": ["11762"],
      Merrick: ["11566"],
      "Mill Neck": ["11765"],
      Mineola: ["11501"],
      "New Cassel": ["11590"],
      "North Bellmore": ["11710"],
      "North Hempstead": ["11576", "11577"],
      "North Massapequa": ["11758"],
      "North Merrick": ["11566"],
      Oceanside: ["11572"],
      "Old Bethpage": ["11804"],
      "Old Brookville": ["11545"],
      "Old Westbury": ["11568"],
      "Oyster Bay": ["11771"],
      Plainview: ["11803"],
      "Port Washington": ["11050"],
      "Rockville Centre": ["11570"],
      Roosevelt: ["11575"],
      Roslyn: ["11576"],
      "Roslyn Estates": ["11576"],
      "Roslyn Heights": ["11577"],
      "Saddle Rock": ["11023"],
      "Sands Point": ["11050"],
      "Sea Cliff": ["11579"],
      Seaford: ["11783"],
      "South Floral Park": ["11001"],
      "Stewart Manor": ["11530"],
      Syosset: ["11791"],
      Uniondale: ["11553"],
      "Upper Brookville": ["11771"],
      "Valley Stream": ["11580", "11581"],
      Wantagh: ["11793"],
      "West Hempstead": ["11552"],
      Westbury: ["11590"],
      "Williston Park": ["11596"],
      Woodbury: ["11797"],
      Woodsburgh: ["11598"],
    },
  };

  const cityOptions = useMemo(() => {
    return Object.entries(neighborhoodData).flatMap(([county, cities]) =>
      Object.keys(cities).map((cityName) => ({
        value: `${county}-${cityName}`,
        label: cityName,
        group: county,
      }))
    );
  }, []);

  const getZipCodeOptions = (selectedCity) => {
    if (!selectedCity) return [];
    const [county, cityName] = selectedCity.split("-");
    const zipCodes = neighborhoodData[county]?.[cityName] || [];
    return zipCodes.map((zip) => ({
      value: zip,
      label: zip,
      group: cityName,
    }));
  };

  const zipCodeOptions = useMemo(() => getZipCodeOptions(city), [city]);

  const handleCityChange = (newCity) => {
    setCity(newCity);
    setZipCode("");
  };

  return (
    <div>
      <div className="relative overflow-hidden -mt-1 bg-black">
        <div className="absolute w-full flex items-center justify-center">
          <img
            src="/assets/productbanner.jpeg"
            alt="Checkout"
            className="brightness-50 mt-[10] md:mt-[-50%] absolute border w-full"
          />
        </div>
        <div className="py-6 text-white relative container font-light px-3">
          <div className="flex items-center gap-2">
            <Link to={"/"}>
              <HomeIcon />
            </Link>
            <FaAngleRight />
            <p>Account</p>
            <FaAngleRight />
            <p className="text-yellow-500">Profile</p>
          </div>
        </div>
      </div>
      <div className="container flex gap-10 h-full bg-white md:px-8">
        <div className="hidden lg:block max-w-[300px] w-full">
          <AccountNav />
        </div>
        <div className="rounded-lg w-full my-5">
          <div className="p-4 block md:hidden sticky top-0 bg-white z-20">
            <div className="border px-2 py-3 rounded-lg flex items-center justify-between">
              <h3 className="font-semibold">Manage My Account</h3>
              <NavigationMenu />
            </div>
          </div>
          <div className="flex flex-col gap-6 p-5 shadow-md shadow-neutral-100 rounded-2xl">
            <div className="flex">
              <h2 className="py-1 text-lg font-semibold border-b-2">
                My Profile
              </h2>
            </div>
            <div className="size-[70px] rounded-full relative cursor-pointer">
              <img
                src={
                  user_info?.user_image
                    ? `${API_URL}${user_info?.user_image}`
                    : "/assets/usericon.png"
                }
                alt="user"
                className="size-[70px] border-2 border-ourPrimary rounded-full cursor-pointer overflow-hidden object-cover"
              />
              <input
                type="file"
                onChange={(e) => updateProfilePicture(e)}
                className="absolute h-full w-full left-0 top-0 opacity-0 cursor-pointer"
              />
              <div className="absolute size-7 right-0 bottom-0 bg-black/50 rounded-full flex items-center justify-center text-white pointer-events-none">
                <MdModeEdit />
              </div>
            </div>

            <div className="flex flex-col gap-8">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8 w-full">
                <div className="w-full">
                  <p className="text-sm font-semibold text-neutral-600 mb-1">
                    First Name
                  </p>
                  <input
                    type="text"
                    placeholder="Your First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    className="px-5 py-2 rounded-lg bg-transparent border w-full"
                  />
                </div>
                <div className="w-full">
                  <p className="text-sm font-semibold text-neutral-600 mb-1">
                    Last Name
                  </p>
                  <input
                    type="text"
                    placeholder="Your Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    className="px-5 py-2 rounded-lg bg-transparent border w-full"
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div className="w-full">
                  <p className="text-sm font-semibold text-neutral-600 mb-1">
                    Email Address
                  </p>
                  <input
                    type="email"
                    placeholder="Your Email Address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled
                    className="px-5 py-2 rounded-lg bg-transparent border w-full"
                  />
                </div>
                <div className="w-full">
                  <p className="text-sm font-semibold text-neutral-600 mb-1">
                    Mobile Number
                  </p>
                  <input
                    type="number"
                    placeholder="Your Mobile Number"
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                    className="px-5 py-2 rounded-lg bg-transparent border w-full"
                  />
                </div>
              </div>
              <div className="my-3 border-y py-5">
                <div className="text-sm font-semibold text-neutral-600 mb-3">
                  Address <span className="text-red-500">*</span>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                  <div className="w-full">
                    <p className="text-sm font-medium text-neutral-600 mb-1">
                      Street address
                    </p>
                    <input
                      type="text"
                      placeholder="Street address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      className="px-5 py-2 rounded-lg bg-transparent border w-full"
                    />
                  </div>
                  <div className="w-full">
                    <p className="text-sm font-medium text-neutral-600 mb-1">
                      Apt, suites etc
                    </p>
                    <input
                      type="text"
                      placeholder="Apt, suites etc"
                      value={address2}
                      onChange={(e) => setAddress2(e.target.value)}
                      className="px-5 py-2 rounded-lg bg-transparent border w-full"
                    />
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-4 gap-5">
                <div className="w-full">
                  <p className="text-sm font-semibold text-neutral-600 mb-1">
                    City
                  </p>
                  <input
                    type="text"
                    placeholder="Your City"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    className="px-5 py-2 rounded-lg bg-transparent border w-full"
                  />
                  {/* <CustomSearchableSelect
                    options={cityOptions}
                    placeholder="Select a city"
                    value={city}
                    setValue={handleCityChange}
                  /> */}
                </div>
                <div className="w-full">
                  <p className="text-sm font-semibold text-neutral-600 mb-1">
                    Zip Code
                  </p>
                  <input
                    type="number"
                    placeholder="Enter Zip Code"
                    value={zipCode}
                    onChange={(e) => setZipCode(e.target.value)}
                    className="px-5 py-2 rounded-lg bg-transparent border w-full"
                  />
                  {/* <CustomSearchableSelect
                    options={zipCodeOptions}
                    placeholder="Select a zip code"
                    value={zipCode}
                    setValue={setZipCode}
                    disabled={!city}
                  /> */}
                </div>
                <div className="w-full">
                  <p className="text-sm font-semibold text-neutral-600 mb-1">
                    State
                  </p>
                  <Select
                    value={selectedState}
                    defaultValue={selectedState}
                    onValueChange={setSelectedState}
                  >
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="State" />
                    </SelectTrigger>
                    <SelectContent>
                      {usStates.map((country, i) => (
                        <SelectItem value={country} key={i}>
                          {country}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>

                <div className="w-full">
                  <p className="text-sm font-semibold text-neutral-600 mb-1">
                    Country
                  </p>
                  <Select value={country}>
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Country" />
                    </SelectTrigger>
                    <SelectContent>
                      {countries.map((country, i) => (
                        <SelectItem value={country} key={i}>
                          {country}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              </div>

              <div className="flex justify-end">
                <button
                  disabled={isSaving}
                  onClick={handleSave}
                  className="px-5 py-2 rounded-md bg-green-700 text-white hover:bg-green-800 transition-all"
                >
                  {isSaving ? "Saving..." : "Save"}
                </button>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-6 p-5 shadow-md shadow-neutral-100 rounded-2xl mt-8">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div className="w-full">
                <p className="text-sm font-semibold text-neutral-600 mb-1">
                  Current Password
                </p>
                <input
                  type="password"
                  placeholder="Current Password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  className="px-5 py-2 rounded-lg bg-transparent border w-full"
                />
              </div>
              <div className="w-full">
                <p className="text-sm font-semibold text-neutral-600 mb-1">
                  New Password
                </p>
                <input
                  type="password"
                  placeholder="New Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="px-5 py-2 rounded-lg bg-transparent border w-full"
                />
              </div>
            </div>
            <div className="flex items-center justify-end">
              <button
                disabled={isChangingPassword}
                onClick={handleChangePassword}
                className="px-5 py-2 rounded-md bg-green-700 text-white hover:bg-green-800 transition-all"
              >
                {isChangingPassword ? "Changing..." : "Change"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
