import React, { useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";

export default function StripeCheckoutPage({ secret, cartItems, totalAmount }) {
  console.log("cartItem : ", cartItems)
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
  
    if (!stripe || !elements) {
      setLoading(false);
      return;
    }
  
    const { error: submitError } = await elements.submit();
    if (submitError) {
      setErrorMessage(submitError.message);
      setLoading(false);
      return;
    }
  
    // Confirm the payment using Stripe
    stripe.confirmPayment({
      elements,
      confirmParams: {
        // Redirect URL after successful payment
        return_url: `${window.location.origin}/payment-success`,
      },
      redirect:'if_required'
    })
    .then(function(result) {
      if (result.error) {
        // Handle payment confirmation errors here
        setErrorMessage(result.error.message || "An error occurred while processing the payment.");
      } else {
        // Payment successful, now push the purchase event
        console.log("Payment successful!", result);
  
        // Extract the necessary data for the purchase event
        if (result.paymentIntent?.status === 'succeeded') {
          if (window.gtag) {
            const deliveryTax = cartItems?.cart?.taxes?.find(t =>
              ["Standard Delivery", "Express Delivery", "Store Pickup"].includes(t.tax_type)
            )?.tax_amount || 0.0;
  
            window.gtag("event", "purchase", {
              transaction_id: result.paymentIntent.id,
              value: cartItems?.cart?.net_total,
              currency: "USD",  // Update with the correct currency
              tax: cartItems?.cart?.taxes_and_charges - deliveryTax,
              shipping: deliveryTax,
              coupon: cartItems?.cart?.is_coupon_applied ? cartItems?.cart?.coupon_code : null,
              items: cartItems?.cart?.items?.map(item => ({
                item_id: item.item_code,
                item_name: item.item_name,
                price: item.price,
                quantity: item.quantity,
                item_category: item.item_category,
                item_brand: item.item_brand,
              })),
            });
          } else {
            console.warn("Google Analytics is not initialized.");
          }
        }
  
        // Redirect the user to the payment success page
        window.location.href = `${window.location.origin}/payment-success`;
      }
    })
    .catch(function(error) {
      setErrorMessage("Unexpected error: " + error.message);
    })
    .finally(() => {
      setLoading(false);
    });
  };  
  

  if (!secret || !stripe || !elements) {
    return (
      <div className="flex items-center justify-center">
        <div
          className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
          role="status"
        >
          <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
            Loading...
          </span>
        </div>
      </div>
    );
  }

  return (
    <form
      onSubmit={handleSubmit}
      className="bg-white p-2 rounded-md max-w-xl mx-auto"
    >
      <PaymentElement
        id="payment-element"
        options={{
          layout: "tabs",
        }}
      />

      {errorMessage && (
        <div className="text-red-500 text-sm mt-3">{errorMessage}</div>
      )}

      <button
        disabled={!stripe || loading}
        className="text-white w-full p-5 bg-black mt-5 rounded-md font-bold disabled:opacity-50 disabled:animate-pulse"
      >
        {!loading ? `Pay Now` : "Processing..."}
      </button>
    </form>
  );
}
