import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";
import { FaRegHeart } from "react-icons/fa";
import { MoveRight } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import {
  get_specialdiscount,
  messageClear,
} from "../../store/reducers/HomeReducer";
import {
  addToWishlist,
  clearMessages,
  generateSessionId,
  updateGuestUserCart,
  updateRegisteredUserCart,
} from "../../store/reducers/CartReducer";
import toast from "react-hot-toast";

const BASE_URL = process.env.REACT_APP_API_URL;

const SpecialDiscount = () => {
  const { special_discount_products, loading } = useSelector(
    (state) => state.home
  );
  const {
    loading: cartLoading,
    successMessage,
    errorMessage,
  } = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userToken = localStorage.getItem("userSecret");
  const [sessionId, setSessionId] = useState(
      localStorage.getItem("session_id")
    );

  useEffect(() => {
    console.log("get_specialdiscount")
    // dispatch(get_specialdiscount());
    const fetchSpecialdiscount = async () => {
      await dispatch(get_specialdiscount({ page: 1, size: 10 }));
    };
    fetchSpecialdiscount();
  }, [dispatch]);

  // useEffect(() => {
  //   if (successMessage) {
  //     toast.success(successMessage);
  //     dispatch(clearMessages());
  //   }
  //   if (errorMessage) {
  //     toast.error(errorMessage);
  //     dispatch(clearMessages());
  //   }
  // }, [dispatch, successMessage, errorMessage]);

  // const handleAddToCart = (id) => {
  //   const actionPayload = { itemCode: id, qty: 1, withItems: 0 };
  //   userToken
  //     ? dispatch(updateRegisteredUserCart(actionPayload))
  //     : dispatch(updateGuestUserCart(actionPayload));
  // };
  const handleAddToCart = async (id) => {
    const addToCart = (sessionId) => {
      const actionPayload = {
        itemCode: id,
        qty: 1,
        withItems: 0,
        sessionId,
      };

      toast.success("Added to Cart")

      if (userToken) {
        dispatch(updateRegisteredUserCart(actionPayload));
      } else {
        dispatch(updateGuestUserCart(actionPayload));
      }
    };

    if (!userToken && !sessionId) {
      const action = dispatch(generateSessionId());
      if (action?.payload?.message) {
        addToCart(sessionId);
      }
    } else {
      addToCart(sessionId);
    }
  };

  const handleWishlist = (id) => {
    if (!userToken) {
      toast.error("Please log in to add to wishlist");
      navigate("/login");
    } else {
      dispatch(addToWishlist({ itemCode: id }));
    }
  };

  return (
    <div className="h-full flex flex-col justify-between p-3">
      <div className="hidden md:flex justify-end items-center mb-4">
        <Link
          to={`/products?specialCategory=${encodeURIComponent("Rice & Dal").trim()}`}
          className="text-green-600 flex items-center hover:text-green-700 transition-all text-[14px] md:text-[18px] font-normal"
        >
          View All <MoveRight className="ml-1" />
        </Link>
      </div>
      <div className="bg-[#edf2ee] rounded-xl h-full">
        <Swiper
          spaceBetween={30}
          pagination={{ clickable: true }}
          modules={[Pagination, Autoplay]}
          autoplay={{ delay: 3000 }}
          loop
          slidesPerView={1}
          className="mySwiper h-full"
        >
          {special_discount_products?.slice(0, 7).map((item, i) => (
            <SwiperSlide key={i} className="h-full p-5 py-10 md:p-10">
              <div className="grid grid-cols-2 gap-4 h-full">
                <div className="flex flex-col gap-2 justify-center">
                  <h4 className="text-2xl font-semibold">Special Discount</h4>
                  <p className="text-sm">This offer ends on 31st March 2025.</p>
                  <div className="mt-2 flex justify-between">
                    <span className="text-xl font-bold">
                      {item.formatted_price}
                    </span>
                    <span className="line-through text-neutral-300">
                      {item.formatted_mrp}
                    </span>
                  </div>
                  <div className="mt-4 flex justify-between">
                    <button
                      onClick={() => handleWishlist(item.item_code)}
                      className="border border-ourPrimary rounded-full p-2 font-bold hover:bg-ourPrimary hover:text-white transition-all"
                    >
                      <FaRegHeart className="text-xl" />
                    </button>
                    <button
                      onClick={() => handleAddToCart(item.item_code)}
                      className="border border-ourPrimary rounded-full px-2 text-xs md:text-sm md:px-5 py-2 font-bold hover:bg-ourPrimary hover:text-white transition-all"
                    >
                      {cartLoading ? "Adding..." : "Add to Cart"}
                    </button>
                  </div>
                </div>
                <div className="flex items-center justify-center">
                  <img
                    src={`${BASE_URL}${item.website_image}`}
                    alt="Product"
                    className="max-w-full h-auto"
                  />
                </div>
              </div>
              {loading && (
                <div className="h-full bg-neutral-200 animate-pulse rounded-lg"></div>
              )}
            </SwiperSlide>
          ))}
          {!loading && special_discount_products.length === 0 && (
            <SwiperSlide className="flex items-center justify-center">
              <h4 className="text-lg font-bold text-center my-10">No Products To Show!</h4>
            </SwiperSlide>
          )}
        </Swiper>
      </div>
    </div>
  );
};

export default SpecialDiscount;
