import React, { useState } from "react";
import { IoCall } from "react-icons/io5";
import { IoMdMail } from "react-icons/io";
import { Link } from "react-router-dom";
import {
  FaFacebook,
  FaApple,
  FaCcDiscover,
  FaCcVisa,
  FaGooglePay,
  FaGooglePlay,
  FaInstagram,
  FaLock,
  FaPinterest,
  FaTwitter,
  FaApplePay,
} from "react-icons/fa";
import { MdOutlineCopyright } from "react-icons/md";
import axios from "axios";
import toast from "react-hot-toast";

const BASE_URL = process.env.REACT_APP_API_URL;

const Footer = () => {
  const [email, setEmail] = useState("");

  const version = process.env.REACT_APP_BUILD_NUMBER || "1.0.0";

  // functions
  const handleSubscribe = async () => {
    const { data } = await axios.post(
      `${BASE_URL}/api/method/keno_store.api.subscribe_to_newsletter`,
      {
        email,
      }
    );
    if (data?.message?.status === "success") {
      toast.success(data?.message?.message);
      setEmail("");
    }
  };

  return (
    <div
      className="bg-gray-950 text-white w-full pb-[60px] md:pb-0"
      translate="no"
    >
      <div className="container mx-auto py-5 md:py-10 px-4 grid grid-cols-1 lg:grid-cols-3 gap-10 w-full place-items-center">
        <div className="min-w-[360px] ml-10 hidden md:flex flex-col items-center justify-center text-center lg:text-left p-3">
          <img
            src="/assets/logo.png"
            alt="Logo"
            className="w-[200px] mx-auto lg:mx-0"
          />
          <p className="mt-8 text-sm text-gray-400">
            Keno Today offers high-quality, halal, and nutritious foods sourced
            from trusted Muslim countries, ensuring authenticity and a balanced,
            healthy lifestyle.
          </p>
          <div className="relative mt-10 flex items-center justify-between rounded-full max-w-[400px] w-full h-auto bg-white mx-auto lg:mx-0">
            <input
              type="text"
              className="px-5 py-3 rounded-full outline-none bg-transparent text-black min-w-0 w-full"
              placeholder="Subscribe Our Newsletter"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button
              onClick={handleSubscribe}
              className="min-h-full bg-ourPrimary right-0 max-w-[120px] rounded-full text-center py-3 px-5"
            >
              Subscribe
            </button>
          </div>
        </div>

        <div className="col-span-2 mt-8">
          <div className="flex items-center justify-center gap-8">
            <div className="flex items-center gap-2">
              <IoCall className="text-ourPrimary" />
              <p className="border-t border-t-ourPrimary">+1 (929) 381-4035 </p>
            </div>
            <div className="flex items-center gap-2">
              <IoMdMail className="text-ourPrimary" />
              <p className="border-t border-t-ourPrimary">info@keno.today</p>
            </div>
          </div>

          <div className="flex flex-col md:flex-row gap-10 my-10 md:my-20">
            <div className="flex gap-10">
              <div>
                <h3 className="md:text-lg">Customer Service</h3>
                <div className="text-neutral-400 text-sm mt-5 flex flex-col gap-2">
                  <Link
                    to="/kenotoday?section=about"
                    className="hover:text-ourPrimary transition-all"
                  >
                    About Keno Today
                  </Link>
                  <Link
                    to="/kenotoday?section=buyer-protection"
                    className="hover:text-ourPrimary transition-all"
                  >
                    Buyer Protection
                  </Link>
                  <Link
                    to="/kenotoday?section=delivery-options"
                    className="hover:text-ourPrimary transition-all"
                  >
                    Delivery Options
                  </Link>
                  <Link
                    to="/kenotoday?section=making-payments"
                    className="hover:text-ourPrimary transition-all"
                  >
                    Making Payments
                  </Link>
                </div>
              </div>
              <div>
                <h3 className="md:text-xl">Join Our Community</h3>
                <div className="text-neutral-400 text-sm mt-5 flex flex-col gap-2">
                  <Link
                    to="/kenotoday?section=making-payments"
                    className="hover:text-ourPrimary transition-all"
                  >
                    Making Payments
                  </Link>
                  <Link
                    to="/kenotoday?section=delivery-options"
                    className="hover:text-ourPrimary transition-all"
                  >
                    Delivery Options
                  </Link>
                  <Link
                    to="/kenotoday?section=terms-conditions"
                    className="hover:text-ourPrimary transition-all"
                  >
                    Terms & Conditions
                  </Link>
                  <Link
                    to="/kenotoday?section=privery"
                    className="hover:text-ourPrimary transition-all"
                  >
                    Privacy
                  </Link>
                  <div className="text-2xl md:mt-10 flex items-center justify-start gap-5">
                    <Link
                      to="https://www.facebook.com/kenotoday"
                      className="hover:text-ourPrimary transition-all"
                      target="_blank"
                    >
                      <FaFacebook />
                    </Link>
                    <Link
                      to="https://www.instagram.com/kenotoday"
                      className="hover:text-ourPrimary transition-all"
                      target="_blank"
                    >
                      <FaInstagram />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <h3 className="text-xl">Download Our App</h3>
              <div className="text-neutral-400 text-sm mt-5 flex flex-col items-center md:items-start gap-2">
                <div className="flex gap-5">
                  <div className="bg-slate-100 text-neutral-950 p-2 rounded-lg flex items-center gap-2">
                    <FaApple className="text-2xl" />
                    <div className="flex flex-col">
                      <p className="text-xs">Download on</p>
                      <a href="https://apps.apple.com/us/app/keno-today/id6736888399" target="_blank" rel="noopener noreferrer">
                        <p className="md:text-lg text-sm">App Store</p>
                      </a>
                    </div>
                  </div>
                  <div className="bg-slate-100 text-neutral-950 p-2 rounded-lg flex items-center gap-2">
                    <FaGooglePlay className="text-2xl" />
                    <div className="flex flex-col">
                      <p className="text-xs">Download on</p>
                      <a href="https://play.google.com/store/apps/details?id=com.keno" target="_blank" rel="noopener noreferrer">
                        <p className="md:text-lg text-sm">Play Store</p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center md:flex-row gap-10">
            <h3>Payment Methods</h3>
            <div className="text-4xl flex items-center justify-center  gap-3">
              <div className="flex items-center gap-5 text-5xl">
                <FaGooglePay />
                <FaCcVisa />
                <FaApplePay />
              </div>
              <img
                src="https://www.logo.wine/a/logo/Mastercard/Mastercard-Logo.wine.svg"
                alt="mastercard"
                className="w-[60px] bg-neutral-500/30 rounded-lg h-full"
              />
              <div className="text-xs bg-neutral-500/30 rounded-lg px-2 py-1">
                <div className="flex items-center gap-1">
                  <FaLock />
                  Secure
                </div>
                <div>payment</div>
              </div>
            </div>
          </div>
        </div>

        <div className="min-w-[360px] ml-10 flex md:hidden flex-col items-center justify-center text-center lg:text-left p-3">
          <img
            src="/assets/logo.png"
            alt="Logo"
            className="w-[200px] mx-auto lg:mx-0"
          />
          <p className="mt-8 text-sm text-gray-400">
            Keno Today offers high-quality, halal, and nutritious foods sourced
            from trusted Muslim countries, ensuring authenticity and a balanced,
            healthy lifestyle.
          </p>
          <div className="relative mt-10 flex items-center justify-between rounded-full max-w-[400px] w-full h-auto bg-white mx-auto lg:mx-0">
            <input
              type="text"
              className="px-5 py-3 rounded-full outline-none bg-transparent text-black min-w-0 w-full"
              placeholder="Subscribe Our Newsletter"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button
              onClick={handleSubscribe}
              className="min-h-full bg-ourPrimary right-0 max-w-[120px] rounded-full text-center py-3 px-5"
            >
              Subscribe
            </button>
          </div>
        </div>
      </div>
      <div className="container mx-auto px-3">
        <div className="text-sm text-neutral-400 flex items-center gap-5 justify-center md:justify-end py-5">
          <Link to="/kenotoday?section=terms-conditions">Terms & Conditions</Link>
          <Link to="/kenotoday?section=privery">Privacy</Link>
        </div>
        <div className="border-t border-t-ourPrimary">
          <div className="flex items-center justify-center py-5 text-neutral-500">
            <MdOutlineCopyright className="text-yellow-600" />
            2025 Keno Today - Version {version}. All Rights Reserved
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
