import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import { Provider } from "react-redux"; // ✅ Import Redux Provider
import store from "./store"; // ✅ Import your Redux store

import { RouterProvider } from "react-router-dom";
import { router } from "./Routes/Routes";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import TagManager from "react-gtm-module"; // Import GTM

const queryClient = new QueryClient();

// Initialize GTM
const tagManagerArgs = {
  gtmId: "GTM-W36TFQQ6", // Replace with your GTM Container ID
  // gtmId: "GTM-M6JRSWJZ", // Replace with your GTM Container ID
};
TagManager.initialize(tagManagerArgs);

ReactDOM.createRoot(document.getElementById("root")).render(
  <Provider store={store}>  {/* ✅ Wrap everything inside Provider */}
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  </Provider>
);
