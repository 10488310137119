import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  get_discountproducts,
  get_hotdeals,
  get_limitedtimeofferproducts,
  get_newproducts,
  get_products_by_category,
  get_riceanddal,
  get_searchproducts,
  get_topsellingproducts,
} from "../../store/reducers/HomeReducer";
import Filter from "./Filter";
import ProductDrawer from "./ProductDrawer";
import ProductCard from "../../components/ProductCard";
import SkeletonCarousel from "../../components/SkeletonCarousel";
import { motion } from "framer-motion";
import { Loader2 } from "lucide-react";

export default function Component() {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [minPrice, setMinPrice] = useState();
  const [maxPrice, setMaxPrice] = useState();
  const [rate, setRate] = useState();
  const [sortValue, setSortValue] = useState("");
  const [allProducts, setAllProducts] = useState([]);
  const [itemsCount, setItemsCount] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(20);
  const [isLoading, setIsLoading] = useState(true);
  const [dataFetched, setDataFetched] = useState(false);
  // const [searchCategory, setSearchCategory] = useState("");

  const [searchParams] = useSearchParams();
  const searchValue = searchParams.get("search");
  const specialCategory = searchParams.get("specialCategory");
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const searchCategory = query.get("category");

  const {
    loading,
    hot_deals,
    top_selling_products,
    new_products,
    search_products,
    discount_products,
    all_products,
    items_count,
    riceanddal,
    limited_time_offer_products,
  } = useSelector((state) => state.home);

  const dispatch = useDispatch();

  const sortProducts = (products, sortValue) => {
    if (sortValue === "lowtohigh") {
      return [...products].sort(
        (a, b) => a.price_list_rate - b.price_list_rate
      );
    } else if (sortValue === "hightolow") {
      return [...products].sort(
        (a, b) => b.price_list_rate - a.price_list_rate
      );
    }
    return products;
  };

  const filterByRating = (products, rating) => {
    if (rating) {
      return products.filter((product) => product.rating >= rating);
    }
    return products;
  };

  const filterByPrice = (products, minPrice, maxPrice) => {
    if (minPrice || maxPrice) {
      return products.filter((product) => {
        const price = product.price_list_rate;
        return (
          (!minPrice || price >= minPrice) && (!maxPrice || price <= maxPrice)
        );
      });
    }
    return products;
  };

  // useEffect(() => {
  //   const query = location.search.split('?')[1];
  //   // const searchCategory = encodeURIComponent(query.get("category") || '');
  //   console.log("SC :", query);
  //   setSearchCategory(searchCategory);
  // }, [location.search]);

  // Update URL when filters change
  useEffect(() => {
    const params = new URLSearchParams(location.search);

    // Update parameters based on state
    if (selectedCategory) params.set("category", selectedCategory);
    else params.delete("category");

    if (minPrice) params.set("minPrice", minPrice);
    else params.delete("minPrice");

    if (maxPrice) params.set("maxPrice", maxPrice);
    else params.delete("maxPrice");

    if (rate) params.set("rate", rate);
    else params.delete("rate");

    // Navigate to the updated URL without adding a new history entry
    navigate({ search: params.toString() }, { replace: false });
  }, [selectedCategory, minPrice, maxPrice, rate]);

  // Restore filter state from URL on component mount
  // useEffect(() => {
  //   const params = new URLSearchParams(location.search);

  //   setSelectedCategory(params.get("category") || "");
  //   setMinPrice(params.get("minPrice") || "");
  //   setMaxPrice(params.get("maxPrice") || "");
  //   // setRate(params.get("rate") || "");
  // }, [location.search]);

  useEffect(() => {
    if (searchCategory) {
      setSelectedCategory(searchCategory);
    }
  }, [searchCategory]);

  useEffect(() => {
    setIsLoading(true);
    setDataFetched(false);
    if (searchValue !== "") {
      dispatch(get_searchproducts({ value: searchValue, page, size })).then(
        () => {
          setTimeout(() => {
            setIsLoading(false);
            setDataFetched(true);
          }, 1000);
        }
      );
    }
  }, [searchValue, dispatch, page, size]);

  useEffect(() => {
    if (search_products) {
      setAllProducts(search_products);
    }
  }, [search_products]);

  useEffect(() => {
    setIsLoading(true);
    setDataFetched(false);
    if (searchCategory) {
      dispatch(
        get_products_by_category({
          category: searchCategory,
          page: page,
          size: size,
        })
      ).then(() => {
        setTimeout(() => {
          setIsLoading(false);
          setDataFetched(true);
        }, 1000);
      });
      console.log(items_count);
      setItemsCount(items_count);
    } else if (selectedCategory) {
      dispatch(
        get_products_by_category({ category: selectedCategory, page, size })
      ).then(() => {
        setTimeout(() => {
          setIsLoading(false);
          setDataFetched(true);
        }, 1000);
      });
    } else {
      dispatch(get_products_by_category({ category: "", page, size })).then(
        () => {
          setTimeout(() => {
            setIsLoading(false);
            setDataFetched(true);
          }, 1000);
        }
      );
    }
  }, [searchCategory, selectedCategory, dispatch, page, size]);

  useEffect(() => {
    if (selectedCategory) {
      setIsLoading(true);
      setDataFetched(false);
      dispatch(
        get_products_by_category({ category: selectedCategory, page, size })
      ).then((response) => {
        setTimeout(() => {
          setAllProducts(response.payload.message.items);
          setIsLoading(false);
          setDataFetched(true);
        }, 1000);
      });
    }
  }, [selectedCategory, dispatch, page, size]);

  useEffect(() => {
    if (
      all_products &&
      !searchValue &&
      !specialCategory &&
      !searchCategory &&
      !selectedCategory
    ) {
      setAllProducts(all_products);
      setItemsCount(items_count);
      setTimeout(() => {
        setIsLoading(false);
        setDataFetched(true);
      }, 1000);
    }
  }, [
    all_products,
    searchValue,
    specialCategory,
    searchCategory,
    selectedCategory,
  ]);

  useEffect(() => {
    setIsLoading(true);
    setDataFetched(false);
    if (specialCategory === "Hot Deals") {
      dispatch(get_hotdeals({ page, size })).then(() => {
        setIsLoading(false);
        setDataFetched(true);
      });
    } else if (specialCategory === "New Products") {
      dispatch(get_newproducts({ page, size })).then(() => {
        setIsLoading(false);
        setDataFetched(true);
      });
    } else if (specialCategory === "Top Selling") {
      dispatch(get_topsellingproducts({ page, size })).then(() => {
        setIsLoading(false);
        setDataFetched(true);
      });
    } else if (specialCategory === "Discount Offers") {
      dispatch(get_discountproducts({ page, size })).then(() => {
        setIsLoading(false);
        setDataFetched(true);
      });
    } else if (specialCategory === "Rice & Dal") {
      dispatch(get_riceanddal({ page, size })).then(() => {
        setIsLoading(false);
        setDataFetched(true);
      });
    } else if (specialCategory === "Limited Time Offer") {
      dispatch(get_limitedtimeofferproducts({ page, size })).then(() => {
        setIsLoading(false);
        setDataFetched(true);
      });
    }
    setSize(20);
  }, [specialCategory, dispatch, page, size]);

  useEffect(() => {
    if (specialCategory === "Hot Deals") {
      setAllProducts(hot_deals);
    } else if (specialCategory === "New Products") {
      setAllProducts(new_products);
    } else if (specialCategory === "Top Selling") {
      setAllProducts(top_selling_products);
    } else if (specialCategory === "Discount Offers") {
      setAllProducts(discount_products);
    } else if (specialCategory === "Rice & Dal") {
      setAllProducts(riceanddal);
    } else if (specialCategory === "Limited Time Offer") {
      setAllProducts(limited_time_offer_products);
    }
  }, [
    specialCategory,
    hot_deals,
    new_products,
    top_selling_products,
    discount_products,
    riceanddal,
    limited_time_offer_products,
  ]);

  useEffect(() => {
    setPage(1);
  }, [selectedCategory, searchCategory, specialCategory]);

  const handleApply = () => {
    let result = [...allProducts];

    if (rate) {
      result = filterByRating(result, rate);
    }

    if (minPrice || maxPrice) {
      result = filterByPrice(result, minPrice, maxPrice);
    }

    if (sortValue) {
      result = sortProducts(result, sortValue);
    }

    setFilteredProducts(result);
  };

  useEffect(() => {
    handleApply();
  }, [rate, minPrice, maxPrice, sortValue, allProducts]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="container p-3 flex gap-5">
      <div className="hidden md:block">
        <Filter
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          minPrice={minPrice}
          maxPrice={maxPrice}
          setMinPrice={setMinPrice}
          setMaxPrice={setMaxPrice}
          rate={rate}
          setRate={setRate}
          handleApply={handleApply}
        />
      </div>

      <div className="w-full">
        <div className="relative">
          <div className="flex items-center justify-between">
            <div className="">
              <div className="block md:hidden sticky top-0">
                <ProductDrawer
                  selectedCategory={selectedCategory}
                  setSelectedCategory={setSelectedCategory}
                  minPrice={minPrice}
                  maxPrice={maxPrice}
                  setMinPrice={setMinPrice}
                  setMaxPrice={setMaxPrice}
                  rate={rate}
                  setRate={setRate}
                  handleApply={handleApply}
                />
              </div>
            </div>
            <div className="relative mb-6">
              <div className="relative inline-block">
                <select
                  value={sortValue}
                  onChange={(e) => setSortValue(e.target.value)}
                  className="appearance-none w-[130px] px-4 py-2.5 bg-white border border-gray-200 rounded-lg text-sm text-gray-700 font-medium hover:border-gray-300  cursor-pointer outline-none text-center"
                >
                  <option value="lowtohigh" className="text-center">
                    Low to High
                  </option>
                  <option value="hightolow" className="text-center">
                    High to Low
                  </option>
                </select>
                {/* <ChevronDown className="absolute right-3 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-500 pointer-events-none" /> */}
              </div>
            </div>
          </div>

          {isLoading || loading || !dataFetched ? (
            <div className="mt-5 flex-grow">
              <SkeletonCarousel full={true} />
            </div>
          ) : filteredProducts.length > 0 ? (
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 p-3">
              {filteredProducts.map((product, i) => (
                <ProductCard product={product} key={i} />
              ))}
            </div>
          ) : (
            <div>
              <motion.div
                initial={{ opacity: 1 }}
                whileInView={{ opacity: 0 }}
                transition={{ delay: 5 }}
                className="mt-5"
              >
                <SkeletonCarousel full={true} />
              </motion.div>
              <motion.h5
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ delay: 5 }}
                className="text-lg font-bold mt-5 text-center"
              >
                No Products to show!
              </motion.h5>
            </div>
          )}
          {allProducts?.length >= size && (
            <div className="mt-5 flex items-center justify-center gap-3">
              <button
                onClick={() => {
                  page !== 1 && setPage(page - 1);
                  page !== 1 && scrollToTop();
                }}
                className="px-5 py-2 rounded-lg bg-ourPrimary text-white hover:bg-ourPrimary-foreground transition-all"
              >
                Prev
              </button>
              <div className="font-bold">{page}</div>
              <button
                onClick={() => {
                  allProducts?.length >= size && setPage(page + 1);
                  allProducts?.length >= size && scrollToTop();
                }}
                className="px-5 py-2 rounded-lg bg-ourPrimary text-white hover:bg-ourPrimary-foreground transition-all"
                disabled={allProducts.length < size}
              >
                Next
              </button>
            </div>
          )}
          {allProducts?.length < size && page > 1 && (
            <div className="mt-5 flex items-center justify-center gap-3">
              <button
                onClick={() => page !== 1 && setPage(page - 1)}
                className="px-5 py-2 rounded-lg bg-ourPrimary text-white hover:bg-ourPrimary-foreground transition-all"
              >
                Prev
              </button>
              <div className="font-bold">{page}</div>
              <button
                onClick={() => {
                  allProducts?.length >= size && setPage(page + 1);
                }}
                className="px-5 py-2 rounded-lg bg-ourPrimary text-white hover:bg-ourPrimary-foreground transition-all"
                disabled={allProducts.length < size}
              >
                Next
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
