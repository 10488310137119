import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const BASE_URL = `${process.env.REACT_APP_API_URL}/api/method`;
const API_TOKEN = "Bearer " + process.env.REACT_APP_PRODUCT_API_TOKEN;

// AsyncThunks (as defined in the previous response)
export const generateSessionId = createAsyncThunk(
  "cart/generateSessionId",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/keno_store.api.generate_session_id`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateRegisteredUserCart = createAsyncThunk(
  "cart/updateRegisteredUserCart",
  async ({ itemCode, qty, withItems = 0 }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/keno_store.cart_api.update_cart`,
        {
          item_code: itemCode,
          qty,
          with_items: withItems,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addToWishlist = createAsyncThunk(
  "cart/addToWishlist",
  async ({ itemCode }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/keno_store.api.add_to_wishlist`,
        {
          item_code: itemCode,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );

      return fulfillWithValue(response.data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const removeFromWishlist = createAsyncThunk(
  "cart/removeFromWishlist",
  async ({ itemCode }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/keno_store.api.remove_from_wishlist`,
        {
          item_code: itemCode,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );

      return fulfillWithValue(response.data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getWishlist = createAsyncThunk(
  "cart/getWishlist",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/keno_store.api.get_wishlist`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );

      return fulfillWithValue(response.data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateGuestUserCart = createAsyncThunk(
  "cart/updateGuestUserCart",
  async ({ sessionId, itemCode, qty, withItems = 0 }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/keno_store.cart_api.update_guest_cart`,
        {
          session_id: sessionId,
          item_code: itemCode,
          qty,
          with_items: withItems,
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const get_cart_quotation = createAsyncThunk(
  "cart/get_cart_quotation",
  async ({ sessionId }, { rejectWithValue }) => {
    const token = "Bearer " + localStorage.getItem("userToken");
    try {
      const response = await axios.post(
        `${BASE_URL}/keno_store.cart_api.get_cart_quotation`,
        {
          session_id: sessionId,
        },
        {
          headers: {
            Authorization: localStorage.getItem("userToken")
              ? token
              : API_TOKEN,
          },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const applyCouponCode = createAsyncThunk(
  "cart/applyCouponCode",
  async (value, { rejectWithValue }) => {
    const token = "Bearer " + localStorage.getItem("userToken");
    try {
      const response = await axios.post(
        `${BASE_URL}/keno_store.cart_api.apply_coupon_code`,
        value,
        {
          headers: {
            Authorization: localStorage.getItem("userToken")
              ? token
              : API_TOKEN,
          },
        }
      );

      console.log(response.data);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const removeCouponCode = createAsyncThunk(
  "cart/removeCouponCode",
  async (value, { rejectWithValue }) => {
    const token = "Bearer " + localStorage.getItem("userToken");
    try {
      const response = await axios.post(
        `${BASE_URL}/keno_store.cart_api.remove_coupon_from_cart`,
        value,
        {
          headers: {
            Authorization: localStorage.getItem("userToken")
              ? token
              : API_TOKEN,
          },
        }
      );

      console.log(response.data);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateCart = createAsyncThunk(
  "cart/updateCart",
  async (
    { itemCode, qty, withItems = 0, isGuest, sessionId },
    { dispatch, getState }
  ) => {
    if (isGuest) {
      if (!sessionId) {
        const sessionResult = await dispatch(generateSessionId());
        sessionId = sessionResult.payload;
      }
      return dispatch(
        updateGuestUserCart({ sessionId, itemCode, qty, withItems })
      );
    } else {
      return dispatch(updateRegisteredUserCart({ itemCode, qty, withItems }));
    }
  }
);

export const placeOrder = createAsyncThunk(
  "cart/placeOrder",
  async (values, { rejectWithValue }) => {
    const token = "Bearer " + localStorage.getItem("userToken");
    try {
      const response = await axios.post(
        `${BASE_URL}/keno_store.cart_api.apply_coupon_code`,
        values,
        {
          headers: {
            Authorization: localStorage.getItem("userToken")
              ? token
              : API_TOKEN,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const re_order = createAsyncThunk(
  "cart/re_order",
  async ({ orderId }, { rejectWithValue }) => {
    const token = "Bearer " + localStorage.getItem("userToken");
    try {
      const response = await axios.post(
        `${BASE_URL}/keno_store.cart_api.apply_coupon_code`,
        {
          order_id: orderId,
        },
        {
          headers: {
            Authorization: localStorage.getItem("userToken")
              ? token
              : API_TOKEN,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const update_cart = createAsyncThunk(
  "cart/update_cart",
  async (value, { rejectWithValue }) => {
    const token = "Bearer " + localStorage.getItem("userToken");
    try {
      const response = await axios.post(
        `${BASE_URL}/keno_store.cart_api.update_cart_details`,
        value,
        {
          headers: {
            Authorization: localStorage.getItem("userToken")
              ? token
              : API_TOKEN,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
      
      return rejectWithValue(error.response.data);
    }
  }
);

export const get_pickup_stores = createAsyncThunk(
  "cart/get_pickup_stores",
  async (value, { rejectWithValue }) => {
    const token = "Bearer " + localStorage.getItem("userToken");
    try {
      const response = await axios.get(
        `${BASE_URL}/keno_store.cart_api.get_pickup_store`,
        {
          headers: {
            Authorization: localStorage.getItem("userToken")
              ? token
              : API_TOKEN,
          },
        }
      );
      
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Cart Slice
const CartReducer = createSlice({
  name: "cart",
  initialState: {
    items: [],
    sessionId: null,
    loading: false,
    errorMessage: null,
    successMessage: null,
    cartItems: [],
    wishListItems: [],
    coupon: false,
    infosave: false,
    stores: {}
  },
  reducers: {
    clearMessages: (state) => {
      state.errorMessage = null;
      state.successMessage = null;
    },
    removeCartItems: (state) => {
      state.cartItems = [];
    },
    handelInfoSave: (state) => {
      state.infosave = false
    }
  },
  extraReducers: (builder) => {
    builder
      // Generate Session ID
      .addCase(generateSessionId.pending, (state) => {
        state.loading = true;
      })
      .addCase(generateSessionId.fulfilled, (state, action) => {
        const sesstion_id = localStorage.getItem("session_id");

        state.loading = false;
        if (!sesstion_id) {
          localStorage.setItem("session_id", action.payload.message.session_id);
          state.sessionId = action.payload.message.session_id;
        } else {
          state.sessionId = localStorage.getItem("session_id");
        }
        // state.successMessage = "Session generated successfully";
      })
      .addCase(generateSessionId.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload || "Failed to generate session";
      })

      // Update Cart (both registered and guest)
      .addCase(updateRegisteredUserCart.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateRegisteredUserCart.fulfilled, (state, action) => {
        state.loading = false;
        // state.items = action.payload.data.me;
        state.successMessage = action.payload.data.message;
      })
      .addCase(updateRegisteredUserCart.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload.data.error || "Failed to update cart";
      })

      // Update Cart (both registered and guest)
      .addCase(updateGuestUserCart.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateGuestUserCart.fulfilled, (state, action) => {
        state.loading = false;
        state.successMessage = action.payload.data.message;
      })
      .addCase(updateGuestUserCart.rejected, (state, action) => {
        state.loading = false;
        // state.errorMessage = action.payload.data.error || "Failed to update cart";
      })

      // add to wishlist
      .addCase(addToWishlist.pending, (state) => {
        state.loading = true;
      })
      .addCase(addToWishlist.fulfilled, (state, action) => {
        state.loading = false;
        state.successMessage = "Product added to Wishlist!";
      })
      .addCase(addToWishlist.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload || "Failed to add!";
      })

      // remove from  wishlist
      .addCase(removeFromWishlist.pending, (state) => {
        state.loading = true;
      })
      .addCase(removeFromWishlist.fulfilled, (state, action) => {
        state.loading = false;
        state.successMessage = action.payload.data.message;
      })
      .addCase(removeFromWishlist.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload || "Failed to add!";
      })

      //get wishlist
      .addCase(getWishlist.pending, (state) => {
        state.loading = true;
      })
      .addCase(getWishlist.fulfilled, (state, action) => {
        state.loading = false;
        state.wishListItems = action.payload.data.wishlist_items;
      })
      .addCase(getWishlist.rejected, (state, action) => {
        state.loading = false;
      })

      //! get Cart (both registered and guest)
      .addCase(get_cart_quotation.pending, (state) => {
        state.loading = true;
      })
      .addCase(get_cart_quotation.fulfilled, (state, action) => {
        state.loading = false;
        state.cartItems = action.payload.data;
      })
      .addCase(get_cart_quotation.rejected, (state, action) => {
        state.loading = false;
      })

      // Apply Coupon Code
      .addCase(applyCouponCode.pending, (state) => {
        state.loading = true;
      })
      .addCase(applyCouponCode.fulfilled, (state, action) => {
        console.log(action);

        state.loading = false;
        // Assuming the API returns updated cart data
        // state.items = action.payload.items;
        state.coupon = true;
        state.successMessage = "Coupon applied successfully";
      })
      .addCase(applyCouponCode.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload.data.error || "Failed to apply coupon";
      })

      // Remove Coupon Code
      .addCase(removeCouponCode.pending, (state) => {
        state.loading = true;
      })
      .addCase(removeCouponCode.fulfilled, (state, action) => {
        console.log(action);

        state.loading = false;
        state.coupon = true;
        state.successMessage = "Coupon removed successfully";
      })
      .addCase(removeCouponCode.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload.data.error || "Failed to remove coupon";
      })

      // re order
      .addCase(re_order.pending, (state) => {
        state.loading = true;
      })
      .addCase(re_order.fulfilled, (state, action) => {
        state.loading = false;
        state.successMessage = "Your order has Placed!";
      })
      .addCase(re_order.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = "Failed to place order!";
      })

      // update cart
      .addCase(update_cart.pending, (state) => {
        state.loading = true;
      })
      .addCase(update_cart.fulfilled, (state, action) => {
        state.loading = false;
        state.infosave = true;
        state.successMessage = "Cart Updated";
      })
      .addCase(update_cart.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = "Failed to Update Cart";
      })

      // get stores
      .addCase(get_pickup_stores.pending, (state) => {
        state.loading = true;
      })
      .addCase(get_pickup_stores.fulfilled, (state, action) => {
        state.loading = false;
        state.stores = action.payload.data.pickup_points
        // state.successMessage = "Cart Updated";
      })
      .addCase(get_pickup_stores.rejected, (state, action) => {
        state.loading = false;
        // state.errorMessage = "Failed to Update Cart";
      })
  },
});

export const { clearMessages, removeCartItems, handelInfoSave } = CartReducer.actions;

export default CartReducer.reducer;
