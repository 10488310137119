import React, { useEffect, useState } from "react";
import ProductCarousel from "../../components/ProductCarousel";
import { MoveRight } from "lucide-react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SkeletonCarousel from "../../components/SkeletonCarousel";
import { get_riceanddal } from "../../store/reducers/HomeReducer";

const RiceAndDal = () => {
  const { riceanddal } = useSelector((state) => state.home);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true); // Local loading state

  useEffect(() => {
    const fetchRiceAndDal = async () => {
      await dispatch(get_riceanddal({ page: 1, size: 15 }));
      setLoading(false); // Set loading to false after fetching products
    };

    fetchRiceAndDal();
  }, [dispatch]);

  return (
    <div className="p-3 md:py-5">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg md:text-2xl font-semibold">Rice & Dal</h2>
        <Link
          to={`/products?specialCategory=${encodeURIComponent("Rice & Dal").trim()}`}
          className="text-green-600 flex items-center hover:text-green-700 transition-all text-[14px] md:text-[18px] font-normal md:hidden"
        >
          View All{" "}
          <span className="ml-1">
            <MoveRight />
          </span>
        </Link>
      </div>

      <div>
        {loading ? (
          // Show loading skeleton when loading
          <SkeletonCarousel />
        ) : (
          <>
            <ProductCarousel products={riceanddal} />
            {riceanddal.length === 0 && (
              <h4 className="my-10 text-lg font-bold flex items-center justify-center">
                No Products to show!
              </h4>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default RiceAndDal;
